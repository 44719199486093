@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&family=Roboto:wght@400;700&display=swap');
@import url('../node_modules/@fortawesome/fontawesome-free/css/all.css');

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  @apply text-gray-700;
}

body {
  font-family: 'Roboto', sans-serif;
  @apply bg-gray-100;
}